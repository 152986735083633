<template>
  <section class="content">
    <div class="card card-primary">
      <!-- form start -->
      <form role="form" id="form_user" class="form-summary">
        <div class="card-body">
          <div class="col-md-12">
            <div class="row">
              <div class="col-md-6 mb-5">
                <h5>Data Shipper</h5>
                <hr style="border: 1px solid #717171;">
                <div class="form-group row">
                  <label class="col-6">Nama Lengkap</label>
                  <span class="col-6">{{ form.nama }}</span>
                </div>
                <div class="form-group row">
                  <label class="col-6">NIK</label>
                  <span class="col-6">{{ form.nik }}</span>
                </div>
                <div class="form-group row">
                  <label class="col-6">No. Telepon</label>
                  <span class="col-6">{{ form.nomor }}</span>
                </div>
                <div class="form-group row">
                  <label class="col-6">Email</label>
                  <span class="col-6">{{ form.email }}</span>
                </div>
                <div class="form-group row">
                  <label class="col-6">Alamat</label>
                  <span class="col-6">{{ !form.ktp_alamat ? '--' : form.ktp_alamat }}</span>
                </div>
              </div>
              <div class="col-md-6 mb-5">
                <h5>Foto KTP</h5>
                <hr style="border: 1px solid #717171;">
                <div class="container-img">
                  <img :src="imgKtp" alt="Boscod" style="width:100%; height: 145px; opacity: 0.5; object-fit: cover;">
                  <div class="centered" v-on:click="lihatKTP">Lihat</div>
                </div>
              </div>
              <div class="col-md-6 mb-5" v-if="form.ktp_verify == 2">
                <h5>Approval</h5>
                <hr style="border: 1px solid #717171;">
                <div class="form-group row">
                  <label class="col-6">Tanggal Pengajuan Verifikasi</label>
                  <span class="col-6">{{ formatDate(formRequest.ktp_verify_created, "DD MMMM YYYY - HH:mm") }}</span>
                </div>
                <div class="form-group row">
                  <label class="col-6">Status Approval</label>
                  <span class="col-6 text-bold"
                    v-bind:class="{ 'text-success': formRequest.ktp_verify == 1, 'text-warning': formRequest.ktp_verify == 2, 'text-danger': formRequest.ktp_verify == 3 }">{{
                        setTextStatus(formRequest.ktp_verify)
                    }}</span>
                </div>
                <div class="form-group row">
                  <label class="col-6">Tanggal Sign Up</label>
                  <span class="col-6">{{ formatDate(formRequest.tanggal_signup, "DD MMMM YYYY") }}</span>
                </div>
                <div class="form-group row">
                  <label class="col-6">{{ formRequest.ktp_verify == 3 ? 'Tanggal Reject' : 'Tanggal Approval' }}</label>
                  <span class="col-6">{{ !formRequest.ktp_verify_on ? '--' : formatDate(formRequest.ktp_verify_on, "DD MMMM YYYY - HH:mm") }}</span>
                </div>
                <div class="form-group row">
                  <label class="col-6">{{ formRequest.ktp_verify == 3 ? 'Rejected by' : 'Approved by' }}</label>
                  <span class="col-6">{{ !formRequest.admin ? '--' : formRequest.admin }}</span>
                </div>
                <div class="form-group row">
                  <label class="col-6">
                    Model Selfie KTP
                    <div class="text-primary cursor-pointer mt-1" @click="showDialogTemplate()">Lihat Model Selfie <i class="fi fi-rr-angle-right"></i></div>
                  </label>
                  <span class="col-6">{{ formRequest.image_template > 0 ? 'Model ' + formRequest.image_template : '--' }}</span>
                </div>
                <div class="mt-1">
                  <button type="button" class="btn btn-success mr-2" v-on:click="approveReq" v-if="form.ktp_verify == 2"
                    :disabled="processing">
                    <i class="fas fa-check-circle"></i>
                    Approve
                    <span v-if="processing" class="spinner-border spinner-border-sm" role="status"
                      aria-hidden="true"></span>
                  </button>
                  <button type="button" class="btn btn-danger mr-1" v-on:click="rejectReq" v-if="form.ktp_verify == 2"
                    :disabled="processing2">
                    <i class="fas fa-times-circle"></i>
                    Reject
                    <span v-if="processing2" class="spinner-border spinner-border-sm" role="status"
                      aria-hidden="true"></span>
                  </button>
                </div>
              </div>
              <div class="col-md-6 mb-5" v-bind:class="{ 'order-md-12': form.ktp_verify != 2 }">
                <h5>Foto Selfie dengan KTP</h5>
                <hr style="border: 1px solid #717171;">
                <div class="container-img">
                  <img :src="imgSelfie" alt="Boscod"
                    style="width:100%; height: 145px; opacity: 0.5; object-fit: cover;">
                  <div class="centered" v-on:click="lihatSelfie">Lihat</div>
                </div>
              </div>
              <div class="col-md-6" v-bind:class="{ 'order-md-1': form.ktp_verify != 2 }">
                <h5>History</h5>
                <hr style="border: 1px solid #717171;">
                <div style="overflow-x: hidden; overflow-y: auto; height: 295px; width: 100%;">
                  <div v-for="item in history" v-bind:key="item.id">
                    <div class="form-group row">
                      <label class="col-6">Tanggal Pengajuan Verifikasi</label>
                      <span class="col-6">{{ formatDate(item.ktp_verify_created, "DD MMMM YYYY - HH:mm") }}</span>
                    </div>
                    <div class="form-group row">
                      <label class="col-6">Status Approval</label>
                      <span class="col-6 text-bold"
                        v-bind:class="{ 'text-success': item.ktp_verify == 1, 'text-warning': item.ktp_verify == 2, 'text-danger': item.ktp_verify == 3 }">{{
                            setTextStatus(item.ktp_verify)
                        }}</span>
                    </div>
                    <div class="form-group row">
                      <label class="col-6">Tanggal Sign Up</label>
                      <span class="col-6">{{ formatDate(item.tanggal_signup, "DD MMMM YYYY") }}</span>
                    </div>
                    <div class="form-group row">
                      <label class="col-6">{{ item.ktp_verify == 3 ? 'Tanggal Reject' : 'Tanggal Approval' }}</label>
                      <span class="col-6">{{ !item.ktp_verify_on ? '--' : formatDate(item.ktp_verify_on, "DD MMMM YYYY - HH:mm") }}</span>
                    </div>
                    <div class="form-group row">
                      <label class="col-6">{{ item.ktp_verify == 3 ? 'Rejected by' : 'Approved by' }}</label>
                      <span class="col-6">{{ !item.admin ? '--' : item.admin }}</span>
                    </div>
                    <div class="form-group row" v-if="item.ktp_verify == 3">
                      <label class="col-6">Kategori Reject</label>
                      <span class="col-6">{{ item.kategori_reject }}</span>
                    </div>
                    <hr style="border: 0.3px solid #DDDDDD;">
                    <div class="p-1"></div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
        <!-- /.box-body -->
        <div class="card-footer form-actions">
          <button type="button" class="btn btn-secondary" v-on:click="goBack">
            <i class="fas fa-window-close"></i> Tutup
          </button>
        </div>
      </form>
    </div>
    <div class="modal" tabindex="-1" role="dialog" ref="formDialog" data-backdrop="static">
      <div class="modal-dialog" role="document">
        <div class="modal-content">
          <div class="modal-header">
            <h4 class="modal-title"> {{ modalTitle }} </h4>
            <button type="button" class="close" data-dismiss="modal" aria-label="Close">
              <span aria-hidden="true">&times;</span>
            </button>
          </div>
          <div class="modal-body">
            <img :src="imgDialog" width="100%">
          </div>
          <div class="modal-footer">
            <button type="button" class="btn btn-secondary" data-dismiss="modal">
              Tutup
            </button>
          </div>
        </div>
      </div>
    </div>
		<div class="modal" tabindex="-1" role="dialog" ref="templateDialog" data-backdrop="static">
			<div class="modal-dialog" role="document">
				<div class="modal-content">
					<div class="modal-header">
						<h4 class="modal-title">Model Foto</h4>
						<button type="button" class="close" data-dismiss="modal" aria-label="Close">
							<span aria-hidden="true">&times;</span>
						</button>
					</div>
					<div class="modal-body p-3">
						<div class="row justify-content-center">
							<div class="col-sm-4 p-2">
								<div class="text-center bg-light border rounded">
									<div class="p-2">
										<img :src="require(`../assets/img/model-1.svg`)" class="d-block w-100 p-1">
									</div>
									<label class="h5 font-weight-normal">Model 1</label>
								</div>
							</div>
							<div class="col-sm-4 p-2">
								<div class="text-center bg-light border rounded">
									<div class="p-2">
										<img :src="require(`../assets/img/model-2.svg`)" class="d-block w-100 p-1">
									</div>
									<label class="h5 font-weight-normal">Model 2</label>
								</div>
							</div>
							<div class="col-sm-4 p-2">
								<div class="text-center bg-light border rounded">
									<div class="p-2">
										<img :src="require(`../assets/img/model-3.svg`)" class="d-block w-100 p-1">
									</div>
									<label class="h5 font-weight-normal">Model 3</label>
								</div>
							</div>
							<div class="col-sm-4 p-2">
								<div class="text-center bg-light border rounded">
									<div class="p-2">
										<img :src="require(`../assets/img/model-4.svg`)" class="d-block w-100 p-1">
									</div>
									<label class="h5 font-weight-normal">Model 4</label>
								</div>
							</div>
							<div class="col-sm-4 p-2">
								<div class="text-center bg-light border rounded">
									<div class="p-2">
										<img :src="require(`../assets/img/model-5.svg`)" class="d-block w-100 p-1">
									</div>
									<label class="h5 font-weight-normal">Model 5</label>
								</div>
							</div>
						</div>
					</div>
					<div class="modal-footer">
						<button type="button" class="btn btn-secondary" data-dismiss="modal">Tutup</button>
					</div>
				</div>
			</div>
		</div>
  </section>
  <!-- /.content -->
</template>
<script>
import Vue from "vue";
import { authFetch, auth, formatCurrency } from "@/libs/hxcore";
import $ from "jquery";
import Ticket from "@/dialog/Ticket";
import TicketComment from "@/dialog/TicketComment";
import VTooltip from 'v-tooltip';
import 'v-tooltip/dist/v-tooltip.css';
import moment from "moment";
import Swal from "sweetalert2";

Vue.use(VTooltip, {
  themes: {
    'boscod': {
      $extend: 'tooltip',
      triggers: [
        'hover',
        'focus',
        'click',
        'touch',
      ],
      placement: 'auto',
    },
  },
});

export default {
  name: "Detail Verification KTP",
  data() {
    return {
      url: window.$apiUrl,
      info: {
        id: "",
      },
      history: [],
      groupCategory: [],
      newTicket: false,
      orderCode: "",
      openTicket: false,
      isShowTicket: false,
      expand: true,
      modalTitle: "",
      form: {},
      formRequest: {},
      imgKtp: '',
      imgSelfie: '',
      imgDialog: '',
      textColor: '',
      textStatus: '',
      processing: false,
      processing2: false,
    };
  },
  components: {
    Ticket,
    TicketComment,
  },
  computed: {},
  created: function () {
    moment.locale('id');
    var uinfo = auth.user();

    authFetch("/shipper/verification/groups")
      .then((res) => {
        if (res.status === 201) { } else if (res.status === 400) { }
        return res.json();
      })
      .then((js) => {
        this.groupCategory = js.data;
      });

    // load detail shipper verification
    let id = this.$route.params.id;
    this.loadDetail(id);
  },
  methods: {
    approveReq: function () {
      let self = this;
      self.formRequest.verify = 1; // set approved
      var data = Object.keys(self.formRequest)
        .map((key) => encodeURIComponent(key) + "=" + encodeURIComponent(self.formRequest[key]))
        .join("&");

      self.processing = true;
      Swal.fire({
        html: "Pastikan kembali data shipper sudah benar.<br>Apakah sudah yakin melakukan Approve?",
        showCancelButton: true,
        confirmButtonText: `Yakin`,
        cancelButtonText: "Batal",
      }).then((result) => {
        if (result.isConfirmed) {
          authFetch("/shipper/verification/approval_ktp", {
            method: "PUT",
            body: data,
          })
            .then((res) => {
              return res.json();
            })
            .then((js) => {
              self.processing = false;
              if (js.success) {
                Swal.fire("Proses Berhasil", `${js.msg}`, "success");
                let id = self.$route.params.id;
                self.loadDetail(id);
              } else {
                Swal.fire("Proses gagal", `${js.msg}`, "error");
              }
            });
        } else {
          self.processing = false;
        }
      });
    },
    rejectReq: async function () {
      let self = this;
      var options = {};
      $.map(self.groupCategory, function (o) {
        options[o.id] = o.label;
      });

      self.processing2 = true;
      const { value: rejectCategory } = await Swal.fire({
        title: 'Pilih kategori reject',
        input: 'select',
        inputOptions: options,
        inputPlaceholder: 'Pilih kategori reject',
        showCancelButton: true,
        confirmButtonText: `Yakin`,
        cancelButtonText: "Batal",
        inputValidator: (value) => {
          return new Promise((resolve) => {
            if (value > 0) {
              resolve()
            } else {
              resolve('Kategori reject belum dipilih')
            }
          })
        }
      });

      if (rejectCategory) {
        self.formRequest.verify = 3; // set rejected
        self.formRequest.category = rejectCategory;
        var data = Object.keys(self.formRequest)
          .map((key) => encodeURIComponent(key) + "=" + encodeURIComponent(self.formRequest[key]))
          .join("&");
        authFetch("/shipper/verification/approval_ktp", {
          method: "PUT",
          body: data,
        })
          .then((res) => {
            return res.json();
          })
          .then((js) => {
            self.processing2 = false;
            if (js.success) {
              Swal.fire("Proses Berhasil", `${js.msg}`, "success");
              let id = self.$route.params.id;
              self.loadDetail(id);
            } else {
              Swal.fire("Proses gagal", `${js.msg}`, "error");
            }
          });
        // Swal.fire(`You selected: ${rejectCategory}`)
      } else {
        self.processing2 = false;
      }
    },
    formatDate(data, format = 'DD/MM/YYYY') {
      return moment(data).format(format);
    },
    lihatKTP() {
      const e = this.$refs;
      this.modalTitle = 'Foto KTP';
      this.imgDialog = this.imgKtp;
      $(e.formDialog).modal("show");
    },
    lihatSelfie() {
      const e = this.$refs;
      this.modalTitle = 'Foto Selfie dengan KTP';
      this.imgDialog = this.imgSelfie;
      $(e.formDialog).modal("show");
    },
		showDialogTemplate() {
			$(this.$refs.templateDialog).modal("show");
		},
    loadDetail: function (id) {
      authFetch("/shipper/verification/" + id).then((res) => {
        res.json().then((json) => {
          if (json.success) {
            this.history = json.history;
            this.formRequest = json.data_request;
            this.form = json.data;
            this.imgKtp = this.form.image_ktp;
            this.imgSelfie = this.form.image_selfie;
          }
        });
      });
    },
    // textColor(verify) {
    //     let color = verify == 1 ? 'text-success' : verify == 2 ? 'text-warning' : 'text-danger';
    //     return color;
    // },
    setTextStatus(verify) {
      let status = verify == 1 ? 'Approved' : verify == 2 ? 'Requested' : 'Rejected';
      return status;
    },
    goBack() {
      window.close();
    },
  },
  mounted() {
    // this.orderCode = this.$route.params.id;
    // this.loadInfo(this.orderCode);
    // const e = this.$refs;
    // //$(e.btnCopy).tooltip()
    // $(e.btnCopy).tooltip().on('mouseleave',function(){$(this).tooltip('hide')})
    // $(e.formDialog).on("show.bs.modal", (e) => {
    //   if (this.history.length == 0) {
    //     /*this.history.push({
    //             time: '00.00',
    //             date: '7 Jul',
    //             note: 'Tiba di counter',
    //             branch: 'Jakarta'
    //         },
    //         {
    //             time: '00.00',
    //             date: '7 Jul',
    //             note: 'Tiba di counter',
    //             branch: 'Jakarta'
    //         })*/
    //     this.loadHistory(this.orderCode);
    //   }
    // });
  },
};
</script>
<style>
.btninfo {
  margin: -3px 0 0 -8px;
  font-size: 17px;
  background-color: transparent;
  border: none;
  cursor: pointer;
  overflow: hidden;
  outline: none;
}

button.btninfo:focus {
  outline: none;
}

.container-img {
  position: relative;
  text-align: center;
  color: white;
  border: 1px solid #414141;
  border-radius: 5px;
}

.centered {
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  background-color: #414141;
  padding: 8px 16px;
  border-radius: 4.8px;
  width: 70px;
  height: 30px;
  cursor: pointer;
}

.centered:hover {
  background-color: #000000;
}

.form-summary .form-group {
  margin-bottom: 0;
}

.form-summary .badge {
  font-size: 100%;
}

@keyframes blinker {
  50% {
    opacity: 0;
  }
}

.css-14fpj81-unf-card {
  display: block;
  position: relative;
  margin: 16px;
  padding: 24px;
  border-radius: 8px;
  box-shadow: 0 1px 6px 0 var(--color-shadow, rgba(49, 53, 59, 0.12));
  background-color: var(--color-card, #ffffff);
  cursor: default;
}

.css-h81dkj {
  width: 100%;
  vertical-align: top;
  display: flex;
  min-height: 60px;
}

.css-h81dkj .date {
  text-align: right;
  width: 18%;
  flex-shrink: 0;
  color: var(--N200, #9fa6b0);
  font-size: 0.857143rem;
  line-height: 1.5;
}

.css-h81dkj .line-wrapper {
  flex-basis: 0px;
  margin: 0px 16px;
  -webkit-box-pack: center;
  justify-content: center;
  -webkit-box-align: center;
  align-items: center;
  flex-direction: column;
  width: 3%;
  display: flex;
}

.css-h81dkj:first-child .circle {
  background-color: transparent;
  animation: blinker 1s linear infinite;
}

.css-h81dkj .circle {
  width: 15px;
  height: 15px;
  border-radius: 50%;
  position: relative;
  margin-bottom: auto;
  background-color: var(--N100, #dbdee2);
}

.css-h81dkj .line-wrapper .line {
  width: 1px;
  height: calc(100% - 9px);
  margin-bottom: -6px;
  background-image: linear-gradient(to bottom,
      var(--N100, #dbdee2) 55%,
      rgba(255, 255, 255, 0) 55%);
  background-position: left center;
  background-size: 1px 12px;
  background-repeat: repeat-y;
}

.css-h81dkj:first-child .status-wrapper {
  padding-bottom: 36px;
}

.css-h81dkj:first-child .status-wrapper .status {
  font-weight: 800;
  line-height: 1.4;
}

.css-h81dkj .status-wrapper .status {
  color: var(--N500, #52565e);
  line-height: 1.5;
  margin-top: -0.25rem;
}

.css-h81dkj:first-child .status-wrapper .city {
  color: var(--N200, #9fa6b0);
}

.css-h81dkj .status-wrapper .city {
  color: var(--N200, #9fa6b0);
  margin-top: 8px;
}

.css-h81dkj:first-child .circle::before {
  width: 15px;
  position: absolute;
  height: 15px;
  border-radius: 50%;
  background-color: var(--G300, #f9b79d);
  transition: all 0.5s ease-out 0s;
  animation-name: grow;
  animation-duration: 1400ms;
  animation-timing-function: ease-out;
  animation-iteration-count: infinite;
  animation-fill-mode: none;
  animation-play-state: running;
  content: "";
}

.css-h81dkj:first-child .circle::after {
  border-radius: 50%;
  background-color: var(--G500, #e96936);
  content: "";
  position: absolute;
  width: 11px;
  height: 11px;
  left: 2px;
  top: 2px;
}

.css-h81dkj .circle::after {
  border-radius: 50%;
  background-color: var(--N200, #9fa6b0);
  content: "";
  position: absolute;
  width: 9px;
  height: 9px;
  left: 3px;
  top: 3px;
}

.css-h81dkj:last-of-type .line {
  display: none;
}

.css-h81dkj .line-wrapper .line {
  width: 1px;
  height: calc(100% - 9px);
  margin-bottom: -6px;
  background-image: linear-gradient(to bottom,
      var(--N100, #dbdee2) 55%,
      rgba(255, 255, 255, 0) 55%);
  background-position: left center;
  background-size: 1px 12px;
  background-repeat: repeat-y;
}
</style>